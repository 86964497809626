<template>
	<div>
		<el-tree :data="data" :props="defaultProps"  highlight-current @node-click="handleNodeClick" :config="config"
			:default-checked-keys="[checkedID]" :node-key="treeNodeKey"  :auto-expand-parent="false" :default-expand-all="true"></el-tree>
	</div>
</template>
<script>
	export default {
		data() {
			return {
				data: [],
				defaultProps: {
					children: 'Children',
					label: 'Name'
				},
				checkedID: "",
				treeNodeKey: "Id"
			};
		},
		props: {
			config: {
				code: null,
				type: null
			},
		},
		mounted() {
			this.init(); //加载表格数据
		},
		methods: {
			init() {
				var _this = this;
				_this.$ajax.send("omsapi/organization/gettreeselectlist", "get", {
					code:   _this.config.code,
					type: _this.config.type,
				}, (data) => {
					if (data.Result != null) {
						this.data = data.Result;
					}
				});

			},
			handleNodeClick(data) {
				this.$emit("onSelectedRow", data);
			}
		}
	};
</script>
<style>
</style>